.subscription_card {
  margin: 2rem 0;
  // border: none !important;
  border: 1px solid rgb(143, 142, 142) !important;
  background: #efefef;
  &-heading {
    font-size: 22px;
    text-align: center;
  }
  &-feature {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
  }
  &-update {
    align-self: center;
  }
  &-pricing {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    .currency {
      font-size: 14px;
      color: #ff39f8;
    }
    .price {
      font-size: 22px;
      color: #ff39f8;
    }
  }
}
