.card {
  background-color: #1e1e2d !important;
  color: #fff !important;
  &-header {
    h3 {
      color: #fff !important;
    }
  }
}
.form-control {
  background-color: #1b1b29 !important;
  border: none;
}
.content_container {
  background-color: #23233c;
}
.bg-gradient-info {
  background: #052e4b !important;
}
.navbar-vertical {
  background-color: #1e1e2d !important;
  h1 {
    color: #fff !important;
  }
  .nav-link {
    color: #fff !important;
  }
}
.profile {
  border: 1px solid rgb(169, 166, 166) !important;
  color: white !important;
  &__ddl {
    background-color: #1e1e2d !important;
    h6 {
      color: white !important;
    }
    span {
      color: white !important;
    }
    i {
      color: white !important;
    }
  }
}
.dropdown-menu:before {
  background-color: transparent !important;
}
.dropdown-item {
  &:hover {
    background: #1b1b29 !important;
  }
}
.modal-content {
  background-color: #1b1b29 !important;
  color: #fff !important;
  .modal-header {
    h5 {
      color: #fff !important;
    }
    span {
      color: #fff !important;
    }
  }
}
.navbar-collapse {
  background-color: #1b1b29 !important;
}
.navbar-vertical .navbar-nav .nav-link {
  color: #fff !important;
}
